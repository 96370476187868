import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library/esm';

function showGetuserMediaError(e) {
    var message = "contact support and provide the following message:\n\nMessage: " + e.message;
    if (e.name == "NotAllowedError")
        alert("To use the barcode function you must allow access to the webcam.\n\nPlease confirm the browser pop-up, change browser settings or " + message);
    else if (e.name == "NotFoundError")
        alert("No webcam found that support barcode reading.\n\nPlease make sure that no other program or browser tab is using the camera or\n" + message);
    else
        alert("Couldn't start webcam for barcode reading. Please " + message);
}

async function startBarcodeReader(elem) {

    // console.log("Starting QRCode Reader");
    const hints = new Map();
    const formats = [BarcodeFormat.EAN_13, BarcodeFormat.DATA_MATRIX, BarcodeFormat.ITF];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    hints.set(DecodeHintType.TRY_HARDER, false);
    const codeReader = new BrowserMultiFormatReader(hints);

    const videoElem = document.querySelector('#video');
    const canvasElem = document.querySelector('#canvas');
    let ctx = canvasElem.getContext('2d') //, { alpha: false })

    var stopVideo = false; // When the video should stop
    var done = false; // Once we have found the QRCode

    const constraints = {
        audio: false,
        video: {
            facingMode: "environment",
            width: { min: 480, ideal: 640, max: 640 }
            // height: { min: 480 }
        }
    };

    let stream = null;

    try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
        showGetuserMediaError(err)
        return;
    }


    const track = stream.getVideoTracks()[0];

    if (false)
        window.setTimeout(() => {
            console.log(track.getCapabilities());
            console.log(track.getSettings());
        }, 500);

    let stop = () => {
        $('.qrcode_modal').removeClass('qrcode_modal_open');
        $('.qrcode_close').off('click');
        done = true;
        stopVideo = true;
        codeReader.stopContinuousDecode();
        codeReader.reset();
        const tracks = stream.getTracks();
        tracks.forEach(function(track) {
            track.stop();
        });
        videoElem.srcObject = null;
    }

    $('.qrcode_close').click(function() {
        stop();
    });

    $('.qrcode_modal').addClass('qrcode_modal_open');

    // set canvas size = video size when known
    videoElem.addEventListener('loadedmetadata', function() {
        canvasElem.width = videoElem.videoWidth;
        canvasElem.height = videoElem.videoHeight;
    });

    var resultPoints = null;

    videoElem.addEventListener('play', function() {
        var $this = this; //cache

        (function loop() {
            if (!$this.paused && !$this.ended) {
                ctx.drawImage($this, 0, 0);

                if (resultPoints) {
                    ctx.strokeStyle = '#FF0000';
                    ctx.beginPath(); // Start a new path
                    ctx.lineWidth = 5;

                    let last = resultPoints[resultPoints.length - 1];

                    ctx.moveTo(last.getX(), last.getY());
                    for (let resultPoint of resultPoints) {
                        // console.log(resultPoint);
                        ctx.lineTo(resultPoint.getX(), resultPoint.getY());
                    }
                    ctx.stroke(); // Render the path
                }
                if (!done)
                    codeReader.decodeOnce(videoElem, false, false).then(function(result) {
                        // console.log(resultPoints);
                        // console.log(result);
                        resultPoints = result.getResultPoints();
                        $(elem).val(result.text);
                        done = true;
                        setTimeout(function() {
                            stop();
                        }, 500); // Continue rendering for 0,5s to show the result
                    }, function(result) {
                        // console.log("Failed");
                    });

                if (!stopVideo)
                    setTimeout(loop, 1000 / 30); // drawing at 30fps
            }
        })();
    }, 0);

    videoElem.srcObject = stream

    // stops scanning after 10 seconds
    setTimeout(() => {
        //    stop();
    }, 10000);
}

// Install Button for all divs of class qrcode which contain an input
$(() => {
    // Are there any divs of class .qrcode (https://stackoverflow.com/a/28059618/278842)
    if (document.querySelector('.qrcode') !== null) {

        function detectWebcam(callback) {
            let md = navigator.mediaDevices;
            if (!md || !md.enumerateDevices) return callback(false);
            md.enumerateDevices().then(devices => {
                callback(devices.some(device => 'videoinput' === device.kind));
            })
        }

        detectWebcam(function(hasWebCam) {
            if (hasWebCam) {
                console.log('Webcam found');
                if (document.querySelector('.qrcode_modal') == null) {
                    let modalHTML =
                        '<div class="qrcode_modal">\n' +
                        '   <div class="qrcode_modal_header">\n' +
                        '       <h1>Please scan Barcode</h1>\n' +
                        '       <button class="qrcode_close">&#10006;</button>\n' +
                        '   </div>\n' +
                        '   <div class="qrcode_modal_content">\n' +
                        '       <video id="video" width="90%" height="90%" hidden autoplay style="display: none"></video>\n' +
                        '       <canvas id="canvas" width="90%" height="90%"></canvas>\n' +
                        '   </div>\n' +
                        '</div>\n'

                    document.body.insertAdjacentHTML('beforeend', modalHTML);
                }

                // Install qrcodebutton on all inputs which are in a div of class qrcode
                $('div.qrcode input').wrap('<span class="qrcodebutton" />').after($('<span/>').click(function() {
                    startBarcodeReader($(this).prev('input'));
                }));
            } else {
                console.log('Webcam not found');
            }
        })
    }
});